<template>
  <div ref="home" class="home">
    <div class="header">
      <div class="title">
        孩缘相亲
      </div>
      <ul class="title_ul">
        <li @click="scrollIntoView(0)">首页</li>
        <li @click="scrollIntoView(1)">公司介绍</li>
        <li @click="scrollIntoView(2)">优质资源</li>
        <li @click="scrollIntoView(3)">成功案例</li>
        <li @click="scrollIntoView(4)">联系我们</li>
        <li @click="scrollIntoView(5)">升级会员</li>
      </ul>
    </div>
    <img class="tab" ref="gsjs" src="../assets/gsjs.png" alt="">
    <div class="tab zyff" ref="zyff">
      <div class="title">
        资源丰富的高效相亲平台
      </div>
      <div class="text">
        覆盖全国各事业单位、高校、名企的优质单身资源。根据孩子情况，每日为您精准匹配合适的资料。
      </div>
      <div class="carousel_div_top">
        <img v-for="(item, index) in top_List " :key="index" class="carousel_img" @click="userGaze" :src="item" alt="">
      </div>
      <div class="carousel_div_bottom">
        <img v-for="(item, index) in bottom_List " :key="index" class="carousel_img" @click="userGaze" :src="item"
          alt="">
      </div>
    </div>
    <div style="position: relative;">
      <img class="tab" ref="cgal" src="../assets/cgal.png" alt="">
      <img class="cgal img1" src="../assets/1.jpg" alt="">
      <img src="../assets/2.jpg" class="cgal img2" alt="">
      <img src="../assets/3.jpg" class="cgal img3" alt="">
      <img src="../assets/4.jpg" class="cgal img4" alt="">
    </div>

    <div class="imgbox" ref="lxwm">
      <img class="tab" src="../assets/lxwm.png" alt="">
      <img class="qcode_center" src="../assets/画板 391.png" alt="">
      <img class="Public_account" src="../assets/qrcode_for_gh_7800d89fd128_258.png" alt="">
      <img v-show="ongaze == true" @click="userGaze" class="qcode" src="../assets/画板 391.png" alt="">
      <div v-show="ongaze == true" @click="userGaze" class="Overlay"></div>
    </div>
    <div class="sjhy" ref="sjhy">
      <div class="title">
        升级会员：享6大会员特权
      </div>
      <div class="text">
        使用红娘老师提供的孩缘相亲账号登录，购买完成后使用微信小程序享受各项权益
      </div>
      <div class="content">
        <div class="card">
          <div class="card_header">
            <div class="title">
              套餐选择
            </div>
            <input class="input" type="text" placeholder="输入手机号">
          </div>
          <div class="text">平台倡导量入为出、理性消费</div>
          <div class="items">
            <div :class="selectIndex == 0 ? 'item' : 'noitem'" @click="changeIndex(0)">
              <div class="jb">
                每天0.8元
              </div>
              <div class="shangp">
                12个月会员
              </div>
              <div class="money">
                <div class="num">299</div>
                <div class="yuan">元</div>
              </div>
            </div>
            <div :class="selectIndex == 1 ? 'item' : 'noitem'" @click="changeIndex(1)">
              <div class="jb">
                每天3元
              </div>
              <div class="shangp">
                3个月会员
              </div>
              <div class="money">
                <div class="num">269</div>
                <div class="yuan">元</div>
              </div>
            </div>
            <div :class="selectIndex == 2 ? 'item' : 'noitem'" @click="changeIndex(2)">
              <div class="jb">
                每天6.6元
              </div>
              <div class="shangp">
                1个月会员
              </div>
              <div class="money">
                <div class="num">199</div>
                <div class="yuan">元</div>
              </div>
            </div>
          </div>
          <div class="paymoney">
            <div class="pay">
              应付金额：
              <div class="money">{{ paymoney }}</div>
              元
            </div>
          </div>
          <div class="pay_btn">
            立即充值
          </div>
          <div class="card_footer">
            已阅读并同意&nbsp;<div class="xy">充值协议</div>
          </div>
        </div>
        <div class="imgs">
          <img src="../assets/谁看了我.png" alt="">
          <img src="../assets/会员权益-02.png" alt="">
          <img src="../assets/more.png" alt="">
          <img src="../assets/谁收藏了我.png" alt="">
          <img src="../assets/更精准的推荐.png" alt="">
          <img src="../assets/wdxx.png" alt="">
        </div>
      </div>
    </div>
    <div class="connection">
      <div class="title">
        联系方式
      </div>
      <div class="title_en">
        Contact Information
      </div>
      <img class="phone" src="../assets/画板 392.png" alt="">
      <img class="mailbox" src="../assets/画板 392 (1).png" alt="">
      <img class="address" src="../assets/画板 392 (2).png" alt="">
    </div>
    <div class="footer">
      <a class="beian" href="https://beian.miit.gov.cn/">沪ICP备2023017729号-1</a>
      <div class="name">上海缘始科技有限公司</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {
  },
  data() {
    return {
      selectIndex: 0,
      paymoney: 299,
      ongaze: false,
      top_List: [
        require('../assets/pic0.png'),
        require('../assets/pic1.png'),
        require('../assets/pic2.png'),
        require('../assets/pic3.png'),
        require('../assets/pic4.png'),
        require('../assets/pic5.png'),
        require('../assets/pic6.png'),
        require('../assets/pic7.png'),
        require('../assets/pic8.png'),
        require('../assets/pic9.png'),
        require('../assets/pic0.png'),
        require('../assets/pic1.png'),
        require('../assets/pic2.png'),
        require('../assets/pic3.png'),
        require('../assets/pic4.png'),
        require('../assets/pic5.png'),
        require('../assets/pic6.png'),
        require('../assets/pic7.png'),
        require('../assets/pic8.png'),
        require('../assets/pic9.png'),
      ],
      bottom_List: [
        require('../assets/pic10.png'),
        require('../assets/pic11.png'),
        require('../assets/pic12.png'),
        require('../assets/pic13.png'),
        require('../assets/pic14.png'),
        require('../assets/pic15.png'),
        require('../assets/pic16.png'),
        require('../assets/pic17.png'),
        require('../assets/pic18.png'),
        require('../assets/pic19.png'),
        require('../assets/pic10.png'),
        require('../assets/pic11.png'),
        require('../assets/pic12.png'),
        require('../assets/pic13.png'),
        require('../assets/pic14.png'),
        require('../assets/pic15.png'),
        require('../assets/pic16.png'),
        require('../assets/pic17.png'),
        require('../assets/pic18.png'),
        require('../assets/pic19.png'),
      ]
    }
  },
  methods: {
    userGaze() {
      if (!this.ongaze) {
        this.$refs.lxwm.scrollIntoView()
      }
      this.ongaze = !this.ongaze
    },
    changeIndex(index) {
      this.selectIndex = index
      if (index == 0) {
        this.paymoney = 299
      } else if (index == 1) {
        this.paymoney = 269
      } else {
        this.paymoney = 199
      }
    },
    scrollIntoView(index) {
      if (index == 0) {
        this.$refs.home.scrollIntoView()
      } else if (index == 1) {
        this.$refs.gsjs.scrollIntoView()
      } else if (index == 2) {
        this.$refs.zyff.scrollIntoView()
      } else if (index == 3) {
        this.$refs.cgal.scrollIntoView()
      } else if (index == 4) {
        this.$refs.lxwm.scrollIntoView()
      } else if (index == 5) {
        this.$refs.sjhy.scrollIntoView()
      }
    }
  }
}
</script>
<style lang="less">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 1.9rem;
  box-sizing: border-box;

  .header {
    width: 100%;
    height: 1.9rem;
    background-image: url('../assets/header.png');
    background-size: cover;
    display: flex;
    align-items: center;
    color: #fff;
    position: fixed;
    top: 0;
    z-index: 3;

    .title {
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 116px;
      margin-left: 1.4rem;
    }

    .title_ul {
      font-size: 0.42rem;
      font-weight: 500;
      list-style: none;
      margin-left: 2.89rem;
      padding: 0;

      li {
        display: inline;
        margin-right: 0.32rem;
        cursor: pointer;
      }
    }
  }

  .tab {
    width: 19.2rem;
    height: 10.8rem;
  }

  .imgbox {
    position: relative;

    .qcode_center {
      position: absolute;
      top: 4.2rem;
      left: 2.51rem;
      height: 2.58rem;
      width: 2.58rem;
    }

    .Public_account {
      position: absolute;
      top: 4.2rem;
      left: 8.31rem;
      height: 2.58rem;
      width: 2.58rem;
    }

    .qcode {
      position: absolute;
      left: 1.4rem;
      top: 3.28rem;
      height: 4.8rem;
      width: 4.8rem;
      z-index: 2;
    }

    .Overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #282727;
      opacity: 0.7;
    }
  }

  .cgal {
    width: 3rem;
    height: 4.66rem;
    position: absolute;
    top: 4.17rem;
  }

  .img1 {
    left: 2.6rem;
  }

  .img2 {
    left: 6.27rem;
  }

  .img3 {
    left: 9.94rem;
  }

  .img4 {
    left: 13.61rem;
  }

  .zyff {
    background: rgb(242, 242, 242);
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #000000;

    .title {
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.16rem;
      margin-top: 0.7rem;
    }

    .text {
      font-size: 0.36rem;
      font-weight: 400;
      margin-top: 0.23rem;
      line-height: 0.52rem;
      margin-bottom: 0.7rem;
    }

    .carousel {
      width: 90%;
      z-index: 1;
      margin-bottom: 0.2rem;
    }

    .top {
      margin-bottom: 0.23rem;
    }

    .carousel_div_top {
      width: 45.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // overflow: hidden;
      animation: carousel1 28s linear infinite both;

      .carousel_img {
        width: 3.55rem;
        height: 3.3rem;
        margin-right: 1rem;
      }

      @keyframes carousel1 {
        from {
          margin-left: 0;
        }

        to {
          margin-left: -91rem;
        }
      }
    }

    .carousel_div_bottom {
      width: 45.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0.23rem;
      animation: carousel2 28s linear infinite both;

      @keyframes carousel2 {
        from {
          margin-right: 0;
        }

        to {
          margin-right: -91rem;
        }
      }

      .carousel_img {
        width: 3.55rem;
        height: 3.3rem;
        margin-left: 1rem;
      }
    }

    .carousel_img {
      width: 3.55rem;
      height: 3.3rem;
    }
  }

  .sjhy {
    background: linear-gradient(137.39deg, rgb(255, 167, 128) 5.683%, rgba(255, 53, 53, 0.48) 100%);
    height: 10.8rem;
    width: 19.2rem;
    color: #fff;
    padding: 0.48rem 1.53rem 0 1.58rem;
    box-sizing: border-box;

    .title {
      font-size: 0.8rem;
      line-height: 1.16rem;
      font-weight: 500;
      text-align: left;
    }

    .text {
      font-size: 0.40rem;
      line-height: 0.58rem;
      font-weight: 500;
      text-align: left;
      margin-top: 0.14rem;
    }

    .content {
      margin-top: 0.53rem;
      display: flex;

      .card {
        width: 8.02rem;
        height: 7.39rem;
        background-color: #fff;
        border-radius: 40px;
        padding: 0.5rem;
        box-sizing: border-box;
        color: black;

        .card_header {
          display: flex;
          align-items: center;

          .title {
            font-size: 0.42rem;
            font-weight: 500;
            line-height: 0.35rem;
          }

          .input {
            width: 2rem;
            height: 0.4rem;
            font-size: 0.24rem;
            border-radius: 10px;
            margin-left: 0.3rem;
            border: 2px black solid;
            padding: 0.1rem 0.2rem;
            box-sizing: border-box;
          }
        }

        .text {
          font-size: 0.32rem;
          font-weight: 400;
          line-height: 0.35rem;
          color: rgb(153, 152, 152);
          margin-top: 0.23rem;
        }

        .items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.63rem;

          .item {
            width: 2rem;
            height: 1.26rem;
            border-radius: 15px;
            box-sizing: border-box;
            border: 2px solid rgb(255, 15, 130);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .jb {
              border-radius: 10px 0px 10px 0px;
              background: rgb(255, 15, 130);
              width: 1.3rem;
              height: 0.3rem;
              color: #fff;
              font-size: 0.18rem;
            }

            .shangp {
              font-size: 0.24rem;
              font-weight: 500;
              line-height: 0.35rem;
            }

            .money {
              display: flex;
              align-items: baseline;
              justify-content: center;
              color: rgb(255, 15, 130);

              .num {
                font-size: 0.36rem;
              }

              .yuan {
                font-size: 0.2rem;
              }
            }
          }

          .noitem {
            width: 2rem;
            height: 1.26rem;
            border-radius: 15px;
            box-sizing: border-box;
            border: 2px solid #D0D0D0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .jb {
              border-radius: 10px 0px 10px 0px;
              background: #D0D0D0;
              width: 1.3rem;
              height: 0.3rem;
              color: #fff;
              font-size: 0.18rem;
            }

            .shangp {
              font-size: 0.24rem;
              font-weight: 500;
              line-height: 0.35rem;
            }

            .money {
              display: flex;
              align-items: baseline;
              justify-content: center;
              color: rgb(255, 15, 130);

              .num {
                font-size: 0.36rem;
              }

              .yuan {
                font-size: 0.2rem;
              }
            }
          }
        }
      }

      .paymoney {
        font-size: 0.4rem;
        display: flex;
        align-items: baseline;
        margin-top: 0.63rem;

        .pay {
          display: flex;
          color: #333;
          align-items: baseline;

          .money {
            color: #FF0F82;
          }
        }

      }

      .pay_btn {
        width: 5rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FF0F82;
        color: #fff;
        font-size: 0.6rem;
        font-weight: 500;
        border-radius: 20px;
        margin-top: 0.54rem;
        cursor: pointer;
      }

      .card_footer {
        display: flex;
        font-size: 0.4rem;
        color: #333333;
        margin-top: 0.54rem;

        .xy {
          color: #1A78FF;
          cursor: pointer;
        }
      }

      .imgs {
        width: 7.25rem;
        height: 7rem;
        margin-left: 0.82rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: hidden;

        img {
          width: 3.4rem;
          height: 2rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .connection {
    width: 19.2rem;
    height: 5.7rem;
    position: relative;
    background: #F2F2F2;

    .title {
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.16rem;
      color: black;
      position: absolute;
      top: 1.36rem;
      left: 0.91rem;
    }

    .title_en {
      font-size: 0.4rem;
      font-weight: 500;
      line-height: 0.58rem;
      position: absolute;
      color: #FF097F;
      top: 3.17rem;
      left: 0.91rem;
    }

    .phone {
      width: 5.54rem;
      height: 1.73rem;
      position: absolute;
      left: 5.74rem;
      top: 0.79rem;
    }

    .mailbox {
      width: 5.54rem;
      height: 1.73rem;
      position: absolute;
      left: 11.8rem;
      top: 0.79rem;
    }

    .address {
      width: 12.6rem;
      height: 1.73rem;
      position: absolute;
      left: 5.74rem;
      top: 3.17rem;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    font-size: 0.4rem;
    font-weight: 400;
    line-height: 0.24rem;
    background-color: rgba(0, 0, 0, 0.7);
    width: 19.2rem;
    height: 2.22rem;

    .beian {
      color: #fff;
    }
  }
}
</style>
