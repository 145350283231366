<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
!(function (doc, win) {
  var html = doc.documentElement;
  var resize = "resize";
  var fonsize = function () {
    var whdef = 100 / 1920; // 表示1920的设计图,使用100px的默认值
    var wH = window.innerHeight; // 当前窗口的高度
    var wW = window.innerWidth; // 当前窗口的宽度
    var rem = wW * whdef;
    wW && (html.style.fontSize = rem + "px");
  };

  win.addEventListener(
    resize,
    function () {
      fonsize();
      document.querySelector("#app").style.height =
        document.documentElement.clientHeight + "px";
    },
    false
  );
  doc.addEventListener("DOMContentLoaded", fonsize, false);
  fonsize();
})(document, window);
</script>
<style lang="less">
body {
  margin: 0;
  padding: 0;
  font-size: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
